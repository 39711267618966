exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-magazines-tsx": () => import("./../../../src/pages/magazines.tsx" /* webpackChunkName: "component---src-pages-magazines-tsx" */),
  "component---src-pages-shows-tsx": () => import("./../../../src/pages/shows.tsx" /* webpackChunkName: "component---src-pages-shows-tsx" */),
  "component---src-templates-magazine-tsx": () => import("./../../../src/templates/magazine.tsx" /* webpackChunkName: "component---src-templates-magazine-tsx" */),
  "component---src-templates-show-tsx": () => import("./../../../src/templates/show.tsx" /* webpackChunkName: "component---src-templates-show-tsx" */)
}

